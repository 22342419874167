<template>
    <div class="w-full top-0">
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="w-full h-auto mt-6 flex justify-center text-baselg">            
                    <div class="w-full">
                        <p class="text-justify leading-loose font-euclid tracking-widest">
                            {{ processText($processPrintLanguage(getForm.i18n.description, $route.params.locale)) }}
                        </p>
                    </div>
                </div>
            </section>
        </section>
        <div v-if="dividedText">
            <h1></h1>
        </div>
        <section slot="pdf-content" v-if="dividedText">
            <section class="pdf-item">
                <div class="w-full h-auto flex justify-center text-baselg">            
                    <div class="w-full">
                        <p class="text-justify leading-loose font-euclid tracking-widest">
                            {{ divideText($processPrintLanguage(getForm.i18n.description, $route.params.locale)) }}
                        </p>
                    </div>
                </div>
            </section>
        </section>
        <section slot="pdf-content" v-if="location != 'condominiums'">
            <section class="pdf-item">
                <div class="w-full h-full mt-2 text-md flex justify-center">
                    <div class="w-1/2">
                        <div class="flex md:space-x-2 space-x-1 lg:space-x-4 lg:w-1/2 w-1/2" style="margin-left:auto;margin-right:auto;">
                            <PropertyDetailsBig :number='getItems.bedrooms' img="/svg/houseDetails/rooms.svg" color="black"  class="ml-4"/>
                            <PropertyDetailsBig :number='getItems.bathrooms' img="/svg/houseDetails/baths.svg" color="black" class="ml-4"/>
                            <PropertyDetailsBig :number='getItems.parking' img="/svg/houseDetails/garage.svg" color="black"  class="ml-4"/>
                            <PropertyDetailsBig :number='getItems.gross_area + $t("size.span")' img="/svg/houseDetails/area.svg" color="black"  class="ml-4"/>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <section class="pdf-item" v-if="location != 'condominiums'">
            <div class="w-full ml-4 h-full" :class="dividedText ? ' mt-8' : ' mt-4'">
                <div class="w-5/6" style="display:block;margin-left:auto;margin-right:auto;">
                    <div class="grid grid-cols-4 md:grid-cols-4 gap-x-4 gap-y-4 text-baselg">
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.gross_area') }}:</p>
                            <p class="text-left">{{ getItems.gross_area }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.bedrooms') }}:</p>
                            <p class="text-left">{{ getItems.bedrooms }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.floor') }}:</p>
                            <p class="text-left">{{ getItems.floor }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.parking') }}:</p>
                            <p class="text-left">{{ getItems.parking }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.util_area') }}:</p>
                            <p class="text-left">{{ getItems.exterior_area }}</p>   
                        </div>
                        <div class="flex justify-between">
                            <p class="font-bold tracking-wide">{{ $t('properties.bath') }}:</p>
                            <p class="text-left">{{ getItems.bathrooms }}</p>
                        </div>
                        <div class="flex justify-between">         
                            <p class="font-bold tracking-wide">{{ $t('properties.energy') }}:</p>
                            <p class="text-left">{{ processEnergyType(getForm.types.energy) }}</p>
                        </div>
                        <div class="flex justify-between">    
                            <p class="font-bold tracking-wide">{{ $t('properties.elevator') }}:</p>
                            <p class="text-left">{{ getForm.types.elevator }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>
<style type="text/css">h1 {page-break-before:always}</style>
<style scoped>
br{
    display: none;
}

@media print {
br{
    display: block;
}
}
</style>
<script>
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";

export default {
    name: "PDFBody",
    components:{
        PropertyDetailsBig
    },
    props: {
        form: null,
        items:null,
        location: null
    },
    data(){
        return{
            dividedText: false,
            textQty: 1150,
            textIncremented: 0,
        }
    },
    computed:{
        getForm(){
            return this.form
        },
        getItems(){
            return this.items
        }
    },
    methods:{
        processEnergyType(e){
            let isento = {
                pt: 'Isento',
                en: 'Exempt'
            }
            let processo = {
                pt: "Em Processo",
                en: "In Process"
            }

            if(e == 'Isento' || e == 'Exempt')
                return this.$processLanguage(isento)

            if(e == 'Em Processo' || e == 'In Process')
                return this.$processLanguage(processo)

            return e
        },
        processText(t){
            if(t == null)
                return ''

            if(t.length >= this.textQty){
                this.dividedText = true
                
                let incremental = 0
                for(let dotPosition = this.textQty; dotPosition < t.length; dotPosition++){
                    if(t[dotPosition] != ' ')
                        incremental++
                    else
                        break;
                }
                incremental++
                this.textIncremented = this.textQty + incremental
                return t.substr(0, this.textIncremented)
            }

            return t
        },
        divideText(t){
            if(t == null)
                return ''
            
            let incremental = 0
            for(let dotPosition = this.textIncremented; dotPosition < t.length; dotPosition++){
                if(t[dotPosition] == ' ' || t[dotPosition] == '.' || t[dotPosition] == ',')
                        incremental++
                    else
                        break;
            }
            return t.substr(this.textIncremented + incremental, t.length)
        },
    }
}
</script>
