<template>
    <div class="w-full top-0 mt-8">
        <!-- begin:: map -->
        <ListingMap ref="listingMap" :hasTooltip="false" listingType="property" v-if="propertiesToDisplay[0].length != 0" :propertiesToDisplay="propertiesToDisplay"/>
        <!-- end:: map -->
    </div>
</template>
<script>
import ListingMap from '@/components/product/listings/ListingMap.vue';
export default {
    name: "PDFLocation",
    components: {ListingMap},
    props: ['propertiesToDisplay']
}
</script>
