<template>
    <div class="w-full top-0 mt-8">
        <h1></h1>
        <section slot="pdf-content">
            <div :class="'grid-cols-' + 2" class="grid gap-4" v-if="block == 'properties'">
                <div class="w-full h-full overflow-hidden rounded-lg" 
                    v-for="item in images" :key="item.id">
                    <img @click="show(item)" 
                    class="origin-center object-center w-full object-cover" 
                    :src="$mediaFile('properties', ID , item.file)"
                    :lazy-src="$mediaFile('properties', ID , item.file)">
                </div>
            </div>
            <div :class="'grid-cols-' + 2" class="grid gap-4" v-else>
                <div class="w-full h-full overflow-hidden rounded-lg" 
                    v-for="item in images" :key="item.id">
                    <img @click="show(item)" 
                    class="origin-center object-center w-full object-cover" 
                    :src="$mediaFile('condominiums', ID , item.file)"
                    :lazy-src="$mediaFile('condominiums', ID , item.file)">
                </div>
            </div>
        </section>
    </div>
</template>
<style type="text/css">h1 {page-break-before:always}</style>
<script>
export default {
    name: "PDFImages",
    props: ['images', 'block', 'ID']
}
</script>
