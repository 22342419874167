<template>
    <div class="w-full text-base text-darkgray fixed bottom-0">
        <div class="grid grid-cols-10 gap-0">
            <div class="w-full col-span-4">
                <div class="w-full h-full flex items-center">
                    <div>
                        <p>{{ Phone }}</p>
                        <p>{{ Aviso }}</p>
                        <p>{{ Email }}</p>
                        <p>{{ Website }}</p>
                    </div>
                </div>
            </div>
            <div class="w-full col-span-4">
                <div class="w-full h-full flex items-center">
                    <div>
                        <p>{{ Address1 }}</p>
                        <p>{{ Address2 }}</p>
                    </div>
                </div>
            </div>
            <div class="w-full right-0 col-span-2">
                <div class="w-full h-full flex items-center flex justify-end">
                    <div class="mt-6">
                        <inline-svg class="right-0" width="5rem" height="5rem" fill="darkgray" src="/svg/logos/logoLAbig.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "PDFFooter",
    data(){
        return{
            Phone: "(+351) 962 493 594*, (+351) 966 764 147*",
            Email: "agency@la-agency.com",
            Website: "www.la-agency.com",
            Address1: "Av. da Liberdade",
            Address2: "110 1269-046 Lisbon, PT",
            Aviso: "*CALL NO NATIONAL MOBILE NETWORK",
        }
    }
}
</script>
