<template>
    <div class="w-full h-full">
        <div class="w-full h-full flex justify-center">
            <div class="w-96 h-56 flex justify-center mt-4">
                <img                    
                    :src="$mediaFile(getLocation, getID, getCover)"
                    class="rounded-lg w-96 h-56 object-cover"
                >  
            </div>
        </div>
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="mt-4 relative w-full h-full">
                    <p class="text-lg" v-if="getLocation != 'condominiums'">
                        {{ $processPrintLanguage(getCityName, $route.params.locale) }} - <span class="text-md">{{reference}}</span>
                    </p>
                    <p class="text-lg" v-else>
                        {{ getCityName }} {{reference}}
                    </p>
                    <p class="font-blackMango lg:w-full font-bold text-lg mt-2 uppercase tracking-widest">
                        {{$processPrintLanguage(getForm.i18n.title, $route.params.locale)}}
                    </p>
                </div>
            </section>
        </section>
        <section slot="pdf-content">
            <section class="pdf-item">
                <div class="mt-4 lg:w-full">
                    <p class="lg:mt-0 lg:w-1/2 w-full text-lg text-bronze font-bold font-euclid tracking-widest">
                        {{ getPriceComputed }}
                    </p>
                </div>
            </section>
        </section>        
    </div>
</template>
<script>

export default {
    name: "PDFHeader",
    props: {
        location: String,
        ID: String,
        cover: null,
        cityName: null,
        form: null,
        reference: null
    },
    computed:{
        getPriceComputed(){            
            if(this.getForm.hide_price == 1)
                return this.$t('price_under_request')

            if(this.location != 'condominiums')
                return String(this.$currency(this.getForm.price))
            else
                return String(this.$currency(this.getForm.starting_price))
        },
        getForm(){
            return this.form
        },
        getLocation(){
            return this.location
        },
        getID(){
            return this.ID
        },
        getCover(){
            return this.cover
        },
        getCityName(){
            return this.cityName
        }
    }
}
</script>
