<template>
    <div class="w-full h-full">
        <div id="printViewContent">
            <PDFHeader
                :location="getLocation"
                :ID="getID"
                :cityName="computeCityName"
                :cover="getCover"
                :form="computeForm"
                :reference="reference"
            />
            <PDFBody
                :form="computeForm"
                :items="computeItems" 
                :location="getLocation"
            />

            <PDFImages :block="getLocation" :ID="ID" :images="computeMedias"/>

            <PDFLocation v-if="getGoogleMap[0].length != 0" :propertiesToDisplay="getGoogleMap"/>
        
            <PDFFooter id="footer"/>
        </div>
    
       
    </div>
</template>
<style>
#printViewContent{
    width: 70%;
    margin: auto;
    border: solid 1px black;
    margin-top: 45px;
    margin-bottom: 45px;
}

#footer {
  color: darkgray;
  visibility: hidden;
}

@page {
  size: A4;
  margin: 11mm 17mm 2mm 17mm;
}

@media print {
    #printViewContent{
    width: 100%;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
}
  #footer {
    bottom: 0;
    position: fixed;
    width: 100%;
    visibility: visible;
  }

  #content-block, p {
    page-break-inside: avoid;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }
}
</style>
<script>
import PDFHeader from '@/components/printing/pdfContents/PDFHeader'
import PDFBody from '@/components/printing/pdfContents/PDFBody'
import PDFImages from '@/components/printing/pdfContents/PDFImages'
import PDFLocation from '@/components/printing/pdfContents/PDFLocation'
import PDFFooter from '@/components/printing/pdfContents/PDFFooter'

import { CondominiumForm } from '@/store/structs/condominium'

export default ({
    name: "PrintView",
    components: {
        PDFHeader,
        PDFBody,
        PDFImages,
        PDFLocation,
        PDFFooter
    },
    data(){
        return{
            location: String,
            ID: String,
            cover: null,
            form: CondominiumForm,
            features: null,
            items: null,
            medias: null,
            attachments: null,
            cityName: null,
            googleMapProperties: null,
            reference: null
        }
    },
    created(){
        this.location = localStorage.getItem("location");
        this.ID = localStorage.getItem("ID");
        this.cover = localStorage.getItem("cover");
        this.form = JSON.parse(localStorage.getItem("form"))
        this.features = JSON.parse(localStorage.getItem("features"))
        this.items = JSON.parse(localStorage.getItem("items"))
        this.attachments = JSON.parse(localStorage.getItem("attachments"))
        this.cityName = JSON.parse(localStorage.getItem("cityName"));
        this.medias = JSON.parse(localStorage.getItem("medias"));
        this.googleMapProperties = JSON.parse(localStorage.getItem("googleMap"));

        if(this.location == "condominiums")
            this.reference = ''

        this.$i18n.locale = this.$route.params.locale;

        window.print()
    },
    computed:{
        computeFeatures(){
            return this.features
        },
        computeForm(){
            return this.form
        },
        computeCityName(){
            return this.cityName
        }, 
        computeItems(){
            return this.items
        },
        computeMedias(){
            return this.medias
        },
        getLocation(){
            return this.location
        },
        getID(){
            return this.ID
        },
        getCover(){
            return this.cover
        },  
        getGoogleMap(){
            return this.googleMapProperties
        },
    }
})
</script>
